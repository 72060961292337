import type {QueryParamAdapterComponent} from 'use-query-params'
import {useLocation, useNavigate} from 'react-router'

export const ReactRouterAdapter: QueryParamAdapterComponent = ({children}) => {
  const navigate = useNavigate()

  return children({
    location: useLocation(),
    push: (location) =>
      navigate({search: location.search}, {state: location.state}),
    replace: (location) =>
      navigate(
        {search: location.search},
        {replace: true, state: location.state},
      ),
  })
}
