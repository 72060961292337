import {Icon, IconProps} from '@iconify/react'
import type phIcons from '@iconify-json/ph/icons.json'

import {cn} from '../utils'

export type PhosphorIconName = keyof (typeof phIcons)['icons']

export interface PhosphorIconProps extends IconProps {
  icon: PhosphorIconName
}

export const PhosphorIcon = ({
  icon,
  className,
  ...restProps
}: PhosphorIconProps) => {
  return (
    <Icon
      className={cn(
        'PhosphorIcon',
        `PhosphorIcon-${icon}`,
        'shrink-0',
        className,
      )}
      icon={`ph:${icon}`}
      {...restProps}
    />
  )
}
