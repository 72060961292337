import {type ClassValue, clsx} from 'clsx'
import {twMerge as _twMerge, extendTailwindMerge} from 'tailwind-merge'
import {tailwindConfig} from '@cheddarup/tailwind-config'

export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args))
}

export const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        '[font-size:inherit]',
        ...Object.keys(tailwindConfig.theme.fontSize).map(
          (key) => `text-${key}`,
        ),
      ],
      h: ['[height:unset]', '[block-size:auto]'],
      w: ['[block-size:auto]'],
      'min-h': ['min-h-[auto]'],
      'text-alignment': ['[text-align:unset]'],
      rounded: Object.keys(tailwindConfig.theme.borderRadius).map(
        (key) => `rounded-${key}`,
      ),
      p: ['p-0_5'],
    },
  },
})
