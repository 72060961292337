import {getStringFromChildren, makeAsComponent} from '@cheddarup/react-util'

import {Text, TextProps} from './Text'
import {cn} from '../utils'

export interface EllipsisProps extends TextProps {}

export const Ellipsis = makeAsComponent<'span', EllipsisProps>(
  ({className, title, children, ...restProps}) => (
    <Text
      className={cn(
        'Ellipsis max-w-full overflow-hidden text-ellipsis whitespace-nowrap',
        className,
      )}
      title={title ?? getStringFromChildren(children)}
      {...restProps}
    >
      {children}
    </Text>
  ),
)
