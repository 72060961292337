// @ts-ignore

import {z} from '@cheddarup/util'

import {s3ImageSchema} from './common'
import {fieldSetSchema, tabObjectFieldSchema} from './fields'
import {inventoryGroupItemSchema} from './inventory-group'

export const itemTypeSchema = z.enum([
  'ticket',
  'fixed',
  'donation',
  'recurring',
])
export const tabAmountTypeSchema = z.enum(['open', 'fixed'])

export const tabItemOptionsRecurringSchema = z.object({
  enabled: z.boolean(),
  options: z.record(z.any()).optional(),
})

export const variantOptionSchema = z.object({
  key: z.string(),
  values: z.string().array(),
})

export const variantListingSchema = z.object({
  uuid: z.string(),
  sku: z.string().nullish(),
  amount: z.number(),
  description: z.string().nullish(),
  retailPrice: z.number().nullish(),
  optionValues: z.record(z.string()),
  available_quantity: z.number().nullable(),
  imageId: z.number().nullish(),
  waitlist_count: z.number().optional(),
})

export const tabItemOptionsVariantsSchema = z.object({
  enabled: z.boolean(),
  options: variantOptionSchema.array(),
  listings: variantListingSchema.array(),
})

export const tabItemOptionsPerPersonMaxQuantitySchema = z.object({
  enabled: z.boolean(),
  value: z.number(),
})

export const tabItemOptionsWaitlistSchema = z.object({
  enabled: z.boolean(),
  customMessage: z.string(),
})

export const suggestedAmountsSchema = z.object({
  enabled: z.boolean(),
  values: z.number().array(),
  minimumAmount: z.number().nullable(),
  buttonText: z.string(),
})

export const tabItemOptionsDonationSchema = z.object({
  suggestedAmounts: suggestedAmountsSchema,
})

export const tabItemOptionsFundraisingGoalSchema = z.object({
  enabled: z.boolean(),
  value: z.number(),
})

export const tabItemQuantityDiscountCalculationMethodSchema = z.enum([
  'fixed',
  'percentage',
])

export const tabItemQuantityDiscountSchema = z.object({
  enabled: z.boolean(),
  percentage: z.number().optional(),
  fixedAmount: z.number().optional(),
  minimumQuantity: z.number(),
  calculationMethod: tabItemQuantityDiscountCalculationMethodSchema,
})

export const tabItemSchema = z.object({
  id: z.number(),
  updated_at: z.string(),
  created_at: z.string(),
  deleted_at: z.string().nullish(),
  allow_quantity: z.boolean().nullish(),
  amount: z.number().nullable(),
  amount_type: tabAmountTypeSchema,
  amount_sold: z.number().nullish(),
  name: z.string(),
  description: z.string(),
  fields: tabObjectFieldSchema.array().optional(),
  position: z.number(),
  tab_id: z.number(),
  type: z.literal('TabItem'),
  quantity_limit: z.number().nullish(),
  images: s3ImageSchema.array().optional(),
  anchor: z.string().nullish(),
  parent_id: z.number().nullish(),
  quantity_sold: z.number().optional(),
  total_buyers: z.number().optional(),
  available_quantity: z.number().nullish(),
  catalog_object_id: z.number().nullish(),
  required: z.boolean(),
  hidden: z.boolean(),
  is_pro: z.boolean(),
  is_team: z.boolean(),
  requires_pro: z.boolean().nullable(),
  requires_team: z.boolean(),
  category: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
  timeZone: z
    .object({
      enabled: z.boolean(),
      value: z.string(),
    })
    .optional(),
  inventory_items: inventoryGroupItemSchema.array(),
  linked_forms: z.object({name: z.string(), id: z.number()}).array().optional(),
  options: z.object({
    subcategoryId: z.string().nullish(),
    makeAvailableQuantityPublic: z.boolean().nullish(),
    recurring: tabItemOptionsRecurringSchema.nullish(),
    variants: tabItemOptionsVariantsSchema.nullish(),
    retailPrice: z.number().nullish(),
    perPersonMaxQuantity: tabItemOptionsPerPersonMaxQuantitySchema.nullish(),
    fieldSets: fieldSetSchema.array().nullish(),
    itemSubType: itemTypeSchema.optional(),
    quantityDiscount: tabItemQuantityDiscountSchema.optional(),
    makeTotalCollectedPublic: z.boolean().optional(),
    waitlist: tabItemOptionsWaitlistSchema.optional(),
    donation: tabItemOptionsDonationSchema.optional(),
    fundraisingGoal: tabItemOptionsFundraisingGoalSchema.optional(),
    time: z
      .object({
        startTime: z.string(),
        endTime: z.string(),
        timeZone: z.string(),
      })
      .optional(),
    location: z
      .object({
        address: z.string(),
        city: z.string(),
        state: z.string(),
        country: z.string(),
        zip: z.string(),
      })
      .optional(),
    showAttendeeResponseOnConfirmationEmail: z.boolean().optional(),
    isTaxDeductible: z.boolean(),
  }),
})

export const waitlistSchema = z.object({
  id: z.number(),
  tab_item_id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  quantity_desired: z.number(),
  variant_uuid: z.string().nullish(),
  deleted_at: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type TabItem = z.infer<typeof tabItemSchema>
    // @ts-ignore
    type TabItemOptionsPerPersonMaxQuantity = z.infer<
      typeof tabItemOptionsPerPersonMaxQuantitySchema
    >
    // @ts-ignore
    type TabItemOptionsVariants = z.infer<typeof tabItemOptionsVariantsSchema>
    // @ts-ignore
    type VariantListing = z.infer<typeof variantListingSchema>
    // @ts-ignore
    type VariantOption = z.infer<typeof variantOptionSchema>
    // @ts-ignore
    type TabItemOptionsRecurring = z.infer<typeof tabItemOptionsRecurringSchema>
    // @ts-ignore
    type TabItemOptionsWaitlist = z.infer<typeof tabItemOptionsWaitlistSchema>
    // @ts-ignore
    type TabAmountType = z.infer<typeof tabAmountTypeSchema>
    // @ts-ignore
    type Waitlist = z.infer<typeof waitlistSchema>
    // @ts-ignore
    type TabItemOptionsDonation = z.infer<typeof tabItemOptionsDonationSchema>
    // @ts-ignore
    type TabItemOptionsFundraisingGoal = z.infer<
      typeof tabItemOptionsFundraisingGoalSchema
    >
    // @ts-ignore
    type TabItemQuantityDiscount = z.infer<typeof tabItemQuantityDiscountSchema>
    // @ts-ignore
    type TabItemQuantityDiscountCalculationMethod = z.infer<
      typeof tabItemQuantityDiscountCalculationMethodSchema
    >
    // @ts-ignore
    type SuggestedAmounts = z.infer<typeof suggestedAmountsSchema>
  }
}
