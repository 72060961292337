import * as ProgressPrimitive from '@radix-ui/react-progress'
import {motion} from 'framer-motion'
import {useEffect, useState} from 'react'

import {cn} from '../utils'

export interface ProgressProps extends ProgressPrimitive.ProgressProps {}

export const Progress = ({
  className,
  value,
  children,
  ...restProps
}: ProgressProps) => (
  <ProgressPrimitive.Root
    className={cn(
      'Progress',
      'relative h-1 overflow-hidden bg-gray800 [transform:translateZ(0)]',
      className,
    )}
    value={value}
    {...restProps}
  >
    <ProgressPrimitive.Indicator
      className="Progress-indicator h-full w-full bg-trueWhite"
      asChild
    >
      {children}
    </ProgressPrimitive.Indicator>
  </ProgressPrimitive.Root>
)

export interface TimedProgressProps
  extends Omit<ProgressProps, 'value' | 'children'> {
  initialValue?: number
  transitionDuration?: number
}

export const TimedProgress = ({
  transitionDuration,
  initialValue = 100,
  ...restProps
}: TimedProgressProps) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setValue((prevValue) => (prevValue > 0 ? prevValue - 1 : prevValue))
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <Progress value={value} {...restProps}>
      <motion.div
        animate={{
          x: '-100%',
          transition: {
            type: 'tween',
            ease: 'linear',
            duration: transitionDuration,
          },
        }}
      />
    </Progress>
  )
}
