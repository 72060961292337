import {
  LinkDescriptor,
  Links,
  Location,
  Meta,
  MetaArgs,
  MetaDescriptor,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
} from 'react-router'
import {HelmetProvider} from 'react-helmet-async'
import {Suspense, useEffect} from 'react'
import queryString from 'query-string'
import * as WebUI from '@cheddarup/web-ui'
import baseCss from '@cheddarup/tailwind-config/base.css?url'
import rootCss from 'src/index.css?url'

import {init} from 'src/init'
import config from 'src/config'
import jsonp from 'jsonp'
import {QueryProvider} from '@cheddarup/api-client'
import {QueryParamProvider} from 'use-query-params'
import {ReactRouterAdapter} from 'src/components/QueryParamProviderAdapter'
import {RecaptchaErrorHandler} from 'src/components/RecaptchaErrorHandler'
import {apiClient} from 'src/helpers/client'
import {AuthErrorHandler} from 'src/components/AuthErrorHandler'
import {ManagerRoleProvider} from 'src/components/ManageRoleProvider'
import AppMaintenance from 'src/views/AppMaintenance'
import {SentryErrorBoundary} from 'src/components/ErrorBoundary'
import PageTitleHelmet from 'src/components/PageTitleHelmet'

export function meta(args: MetaArgs) {
  return [
    {
      title:
        'Collect Money Online. Collect from a Group for Free. Get Started Now.',
    },
    {
      name: 'description',
      content:
        'Collect money online in minutes. Cheddar Up helps you collect payments and track information from a group or community. Anyone can pay without having an app or account. You can use Cheddar Up to collect both online and in-person payments for almost anything - from membership dues to group gifts to events to sales.',
    },
    {
      charSet: 'utf-8',
    },
    {
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover',
    },
    {
      name: 'google-play-app',
      content: 'app-id=com.cheddarup.CheddarUp',
    },
    isMobileAppBannerVisible(args.location)
      ? {
          name: 'apple-itunes-app',
          content: `app-id=${config.appStoreAppId}`,
        }
      : undefined,
  ].filter((m) => m != null) satisfies MetaDescriptor[]
}

export function links() {
  return [
    {
      rel: 'apple-touch-icon',
      href: '/favicon.ico',
    },
    {
      rel: 'android-touch-icon',
      href: '/favicon.ico',
    },
    {
      rel: 'shortcut icon',
      href: '/favicon.ico',
    },

    {
      rel: 'preconnect',
      href: 'https://fonts.googleapis.com',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Merriweather:ital@0;1&display=swap',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap',
    },
    {
      rel: 'stylesheet',
      href: baseCss,
    },
    {
      rel: 'stylesheet',
      href: rootCss,
    },
  ] satisfies LinkDescriptor[]
}

init()

export function Layout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <html lang="en">
      <head>
        {/** Start cookieyes banner */}
        {import.meta.env.MODE === 'production' && (
          <script
            id="cookieyes"
            type="text/javascript"
            src="https://cdn-cookieyes.com/client_data/b1f6159c1befb25505dbc154/script.js"
          />
        )}
        <script>{'window.prerenderReady = false;'}</script>
        <script
          defer
          src="https://app.upvoty.com/embed.js?id=cb7c0eb1-401f-4a1a-b14e-dcbceb4e53f2"
        />
        {/** End cookieyes banner */}

        <style>{'.cky-revisit-bottom-left {left: 145px !important;}'}</style>

        <script>
          {`window.zESettings = window.zESettings || {}
window.zESettings.webWidget = window.zESettings.webWidget || {}
window.zESettings.webWidget.color = {
  theme: '#2c7b91',
  launcher: '#f36d36', // This will also update the badge
  launcherText: '#ffffff',
  button: '#2c7b91',
  resultLists: '#691840',
  header: '#2c7b91',
  articleLinks: '#ffffff',
}`}
        </script>

        <script src="https://cdn.withpersona.com/dist/persona-v4.5.0.js" />

        <Meta />
        <Links />
      </head>
      <body className="flex h-dvh flex-col">
        {children}
        <ScrollRestoration />

        {/** Google Tag Manager (noscript) */}
        <noscript>
          {/* biome-ignore lint/a11y/useIframeTitle: <explanation> */}
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-N3D6S7F"
            height="0"
            width="0"
            style={{display: 'none', visibility: 'hidden'}}
          />
        </noscript>
        {/** End Google Tag Manager (noscript) */}

        <script>
          {`;(function (e, t, o, n, p, r, i) {
  e.visitorGlobalObjectAlias = n
  e[e.visitorGlobalObjectAlias] =
    e[e.visitorGlobalObjectAlias] ||
    function () {
      ;(e[e.visitorGlobalObjectAlias].q =
        e[e.visitorGlobalObjectAlias].q || []).push(arguments)
    }
  e[e.visitorGlobalObjectAlias].l = new Date().getTime()
  r = t.createElement('script')
  r.src = o
  r.async = true
  i = t.getElementsByTagName('script')[0]
  i.parentNode.insertBefore(r, i)
})(
  window,
  document,
  'https://diffuser-cdn.app-us1.com/diffuser/diffuser.js',
  'vgo',
)
if (vgo) {
  vgo('setAccount', '798920561')
  vgo('setTrackByDefault', true)
  vgo('process')
}`}
        </script>

        <script>
          {`window.process = window.process || {
  env: {
    DEBUG: undefined,
    NODE_DEBUG: undefined,
  },
}`}
        </script>

        <Scripts />
      </body>
    </html>
  )
}

export function HydrateFallback() {
  return (
    <div className="flex h-screen min-h-0 min-w-0 flex-auto items-center justify-center">
      <WebUI.Loader />
    </div>
  )
}

export function ErrorBoundary() {
  return <SentryErrorBoundary />
}

export default function Root() {
  usePageTracking()
  const location = useLocation()

  useEffect(() => {
    const page = location.pathname.split('/').pop()
    const label = `pagetag${page}`

    window.zE?.('webWidget', 'helpCenter:setSuggestions', {
      url: true,
      labels: [label],
    })
  }, [location.pathname])

  useEffect(() => {
    try {
      window.convertflow?.start()
    } catch {
      // noop
    }
  }, [])

  return (
    <HelmetProvider>
      <PageTitleHelmet />
      <Suspense
        fallback={
          <div className="flex h-screen min-h-0 min-w-0 flex-auto items-center justify-center">
            <WebUI.Loader />
          </div>
        }
      >
        <QueryProvider>
          <QueryParamProvider
            adapter={ReactRouterAdapter}
            options={{
              searchStringToObject: queryString.parse,
              objectToSearchString: queryString.stringify,
              enableBatching: false,
            }}
          >
            <WebUI.UIProvider>
              <ManagerRoleProvider>
                <AppMaintenance>
                  <Outlet />
                </AppMaintenance>
              </ManagerRoleProvider>
              <AuthErrorHandler />
              <RecaptchaErrorHandler axios={apiClient} />
            </WebUI.UIProvider>
          </QueryParamProvider>
        </QueryProvider>
      </Suspense>
    </HelmetProvider>
  )
}

// MARK: – Helpers

function usePageTracking() {
  const location = useLocation()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // TODO: get rid of jsonp
    jsonp(
      `https://trackcmp.net/visit?actid=798920561&e=${window.trackcmp_email}&r=${document.referrer}&u=${window.location.href}`,
    )
  }, [location.pathname])
}

function isMobileAppBannerVisible(location: Location) {
  return (
    !queryString.parse(location.search).hideSmartBanner &&
    !location.pathname.startsWith('/c/') &&
    !location.pathname.startsWith('/marketplace') &&
    !location.pathname.includes('/i/') &&
    !location.pathname.includes('/me/') &&
    location.pathname.replace(/\//g, '') !== 'collections'
  )
}
