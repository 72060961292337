// See https://react-spectrum.adobe.com/react-aria/useToast.html

import * as Ariakit from '@ariakit/react'
import React, {use, useRef} from 'react'
import {
  QueuedToast,
  ToastOptions,
  ToastState,
  ToastStateProps,
  useToastState,
} from '@react-stately/toast'
import {useToast, useToastRegion} from '@react-aria/toast'

import {cn} from '../../utils'
import {useForkRef} from '@cheddarup/react-util'
import {ButtonAnchor, NextButton} from './Button'
import {PhosphorIcon} from '../../icons'
import {SetOptional} from '@cheddarup/util'

export type {ToastOptions}

export type ToastType = 'error' | 'success' | 'undo'

export interface ToastContentInfo {
  type: 'error' | 'success'
  title?: string
  description?: string
}

export interface ToastContentUndo extends Omit<ToastContentInfo, 'type'> {
  type: 'undo'
  onUndo?: () => void
}

export type ToastContent = ToastContentInfo | ToastContentUndo

export type Toast = QueuedToast<ToastContent>

// MARK: – ToastsContext

export interface ToastsContextValue extends ToastState<ToastContent> {}

const ToastsContext = React.createContext<ToastsContextValue | null>(null)

export interface ToastsProviderProps extends ToastStateProps {
  children: React.ReactNode
}

export const ToastsProvider = ({
  children,
  maxVisibleToasts = Number.MAX_SAFE_INTEGER,
  hasExitAnimation = true,
}: ToastsProviderProps) => {
  const toastState = useToastState<ToastContent>({
    maxVisibleToasts,
    hasExitAnimation,
  })
  return <ToastsContext value={toastState}>{children}</ToastsContext>
}

// MARK: – ToastsStack

export interface ToastsStackProps extends Ariakit.RoleProps<'div'> {}

export const ToastsStack = ({
  className,
  ref: refProp,
  ...restProps
}: ToastsStackProps) => {
  const ownRef = React.useRef<HTMLDivElement>(null)
  const ref = useForkRef(refProp, ownRef)
  const state = use(ToastsContext)

  if (!state) {
    throw new Error("ToastsStack can't be used outside of ToastsProvider")
  }

  const {regionProps} = useToastRegion(restProps, state, ownRef)

  return (
    <Ariakit.Role.div
      ref={ref}
      className={cn('inline-grid place-items-center items-end', className)}
      {...regionProps}
      {...restProps}
    >
      {state.visibleToasts.map((toast, idx, toasts) => (
        <Toast
          key={toast.key}
          data-animation={toast.animation}
          className={cn(
            'data-[animation=entering]:slide-in-from-right data-[animation=entering]:animate-in',
            'data-[animation=exiting]:slide-out-to-right data-[animation=exiting]:animate-out',
            '[grid-column-start:1] [grid-row-start:1]',
            'transition-transform duration-200',
          )}
          style={{
            transform: `translateY(${Math.min(toasts.length - idx, 2) * 5}%)`,
          }}
          toast={toast}
          onAnimationEnd={() => {
            if (toast.animation === 'exiting') {
              state.remove(toast.key)
            }
          }}
        />
      ))}
    </Ariakit.Role.div>
  )
}

// MARK: – Toast

export interface ToastProps extends Ariakit.RoleProps<'div'> {
  toast: QueuedToast<ToastContent>
}

export const Toast = ({
  toast,
  className,
  ref: refProp,
  ...restProps
}: ToastProps) => {
  const ownRef = useRef<HTMLDivElement>(null)
  const ref = useForkRef(refProp, ownRef)
  const state = use(ToastsContext)

  if (!state) {
    throw new Error("Toast can't be used outside of ToastsProvider")
  }

  const {toastProps, contentProps, titleProps, descriptionProps} = useToast(
    {toast, ...restProps},
    state,
    ownRef,
  )

  return (
    <Ariakit.Role.div
      data-type={toast.content.type}
      ref={ref}
      className={cn(
        'group/toast flex w-screen flex-row items-center gap-4 rounded p-4 shadow-600 sm:w-[440px]',
        'data-[type=error]:bg-orange-100 data-[type=success]:bg-teal-200 data-[type=undo]:bg-grey-600',
        className,
      )}
      {...toastProps}
      {...restProps}
    >
      <div className="flex grow flex-row items-center gap-3" {...contentProps}>
        <PhosphorIcon
          className={cn(
            'box-content rounded-full border-[5px] text-ds-xl text-trueWhite',
            'group-data-[type=error]/toast:border-orange-500 group-data-[type=success]/toast:border-teal-600 group-data-[type=undo]/toast:border-orange-500',
            'group-data-[type=error]/toast:bg-orange-500 group-data-[type=success]/toast:bg-teal-600 group-data-[type=undo]/toast:bg-trueWhite',
            'group-data-[type=undo]/toast:[&_path]:fill-orange-500',
          )}
          icon={
            (
              {
                success: 'check-circle-fill',
                error: 'prohibit-fill',
                undo: 'info-fill',
              } as const
            )[toast.content.type]
          }
        />

        <div className="flex grow flex-col gap-1">
          {!!toast.content.title && (
            <div
              className="font-medium group-data-[type=error]/toast:text-orange-700 group-data-[type=success]/toast:text-teal-800 group-data-[type=undo]/toast:text-trueWhite"
              {...titleProps}
            >
              {toast.content.title}
            </div>
          )}
          {!!toast.content.description && (
            <div
              className="text-ds-sm text-grey-800 group-data-[type=undo]/toast:text-trueWhite"
              {...descriptionProps}
            >
              {toast.content.description}
            </div>
          )}
        </div>

        {toast.content.type === 'undo' && (
          <ButtonAnchor
            className="shrink-0 font-normal text-teal-300"
            onClick={() => {
              if (toast.content.type === 'undo') {
                toast.content.onUndo?.()
              }

              state.close(toast.key)
            }}
          >
            Undo
          </ButtonAnchor>
        )}
      </div>

      <NextButton
        aria-label="Close"
        type="button"
        className="p-1 text-grey-800 group-data-[type=undo]/toast:text-trueWhite"
        size="headless"
        variant="headless"
        onClick={() => state.close(toast.key)}
      >
        <PhosphorIcon icon="x" />
      </NextButton>
    </Ariakit.Role.div>
  )
}

// MARK: – Hooks

export function useToasts() {
  const context = use(ToastsContext)
  if (!context) {
    throw new Error('`useToasts` must be used within a `ToastsProvider`')
  }
  return context
}

export function useToastsActions() {
  const toasts = useToasts()

  return {
    show: <T extends ToastType>(
      type: T,
      content: SetOptional<ToastContent, 'type'>,
      options?: Omit<ToastOptions, 'priority'>,
    ) => {
      toasts.add(
        {...content, type},
        {
          ...options,
          timeout: 5000,
        },
      )
    },
  }
}
