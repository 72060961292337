import {makeAsComponent} from '@cheddarup/react-util'
import {cva} from 'class-variance-authority'

import {VariantsProps, cn} from '../utils'

export const panel = cva('overflow-hidden bg-trueWhite focus:outline-none', {
  variants: {
    variant: {
      default: 'border sm:rounded',
      capsule: 'sm:rounded-extended',
      shadowed: 'shadow-z9',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export interface PanelProps extends VariantsProps<typeof panel> {}

export const Panel = makeAsComponent<'div', PanelProps>(
  ({className, as: Comp = 'div', variant = 'default', ...restProps}) => (
    <Comp className={cn('Panel', panel({variant}), className)} {...restProps} />
  ),
)
