import lottie, {AnimationItem} from 'lottie-web/build/player/lottie_light'
import React, {useImperativeHandle, useLayoutEffect, useRef} from 'react'
import {useForkRef} from '@cheddarup/react-util'

import {cn} from '../utils'

export interface LottieInstance
  extends Pick<AnimationItem, 'play' | 'pause' | 'stop' | 'setSpeed'> {}

export interface LottieProps extends React.ComponentProps<'div'> {
  lottieRef?: React.Ref<LottieInstance>
  animationData?: unknown
  width: React.CSSProperties['width']
  height: React.CSSProperties['height']
  loop?: boolean | number
  autoplay?: boolean
}

export const Lottie = ({
  ref: refProp,
  className,
  lottieRef,
  animationData,
  width,
  height,
  loop,
  autoplay,
  ...restProps
}: LottieProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const animRef = useRef<AnimationItem | null>(null)
  const ref = useForkRef(refProp, containerRef)

  useLayoutEffect(() => {
    const container = containerRef.current
    if (container) {
      const anim = lottie.loadAnimation({
        container,
        animationData,
        renderer: 'svg',
        loop: loop !== false,
        autoplay: autoplay !== false,
      })
      animRef.current = anim
      return () => anim.destroy()
    }
    return
  }, [animationData, autoplay, loop])

  useImperativeHandle(
    lottieRef,
    (): LottieInstance => ({
      play: (...args) => animRef.current?.play(...args),
      pause: (...args) => animRef.current?.pause(...args),
      stop: (...args) => animRef.current?.stop(...args),
      setSpeed: (...args) => animRef.current?.setSpeed(...args),
    }),
    [],
  )

  return (
    <div
      ref={ref}
      role="img"
      aria-label="Animation"
      className={cn('overflow-hidden will-change-transform', className)}
      style={{
        width,
        height,
      }}
      {...restProps}
    />
  )
}
