import {useForkRef} from '@cheddarup/react-util'
import {useRef} from 'react'

import animationData from '../assets/tesselate.lottie.json'
import {Lottie, LottieInstance, LottieProps} from './Lottie'

export interface TesselateSpinnerProps
  extends Omit<LottieProps, 'width' | 'height'> {
  size?: React.CSSProperties['width'] | React.CSSProperties['height']
}

export const TesselateSpinner = ({
  size = '8em',
  loop = true,
  ref: refProp,
  ...restProps
}: TesselateSpinnerProps) => {
  const ownRef = useRef<LottieInstance>(null)
  const ref = useForkRef(refProp, ownRef)

  ownRef.current?.setSpeed(2)

  return (
    <Lottie
      ref={ref as any}
      animationData={animationData}
      width={size}
      height={size}
      loop={loop}
      {...restProps}
    />
  )
}
