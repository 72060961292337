import * as Ariakit from '@ariakit/react'

import {cn, VariantsProps} from '../../utils'
import {cva} from 'class-variance-authority'

export const nextAnchor = cva(
  [
    'group/anchor relative cursor-pointer overflow-hidden transition-colors',
    'text-ellipsis whitespace-nowrap font-body aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
  ],
  {
    variants: {
      variant: {
        default: 'text-teal-600 transition-colors hover:text-teal-600/50',
        text: 'text-inherit',
        headless: '',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface NextAnchorProps
  extends VariantsProps<typeof nextAnchor>,
    Ariakit.RoleProps<'a'> {
  disabled?: boolean
}

export const NextAnchor = ({
  'aria-disabled': ariaDisabled,
  disabled,
  variant,
  render,
  className,
  href,
  ...restProps
}: NextAnchorProps) => (
  <Ariakit.Role.a
    aria-disabled={ariaDisabled ?? disabled}
    className={cn(nextAnchor({variant}), className)}
    href={disabled ? undefined : href}
    render={disabled ? <Ariakit.Role.span render={render} /> : render}
    {...restProps}
  />
)
