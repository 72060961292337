import {motion} from 'framer-motion'
import {useUpdateEffect} from '@cheddarup/react-util'
import React, {use, useEffect, useMemo, useState} from 'react'
import {SetRequired, makeShortId} from '@cheddarup/util'
import {tailwindConfig} from '@cheddarup/tailwind-config'

import {PhosphorIcon, PhosphorIconName} from '../icons'
import {Heading} from './Heading'
import {HStack, VStack} from './Stack'
import {IconButton} from './IconButton'
import {Text} from './Text'
import {useMedia} from '../hooks'
import {Button} from './Button'
import {TimedProgress} from './Progress'

export type GrowlAlertType = 'error' | 'success' | 'undo'

export interface GrowlAlertInfoPayload {
  title?: string
  body?: string
  iconName?: PhosphorIconName
  timeout?: number
  onDidClose?: () => void
}

export interface GrowlAlertUndoPayload
  extends Omit<GrowlAlertInfoPayload, 'title'> {
  onUndo?: () => void
}

export type GrowlAlertPayload<T extends GrowlAlertType> = T extends 'undo'
  ? GrowlAlertUndoPayload
  : GrowlAlertInfoPayload

export type Growl<T extends GrowlAlertType = GrowlAlertType> = {
  type: T
  id: string
} & SetRequired<GrowlAlertPayload<T>, 'timeout' | 'iconName'>

export type GrowlAlertsStateContextValue = Growl[]

export interface GrowlAlertsActionsContextValue {
  show: <T extends GrowlAlertType>(
    type: T,
    payload: GrowlAlertPayload<T>,
  ) => void
  clear: () => void
  hide: (growlId: string) => void
}

// TODO: change these to undefined
// the issue is that some components will throw, for example `TrashButton`
export const GrowlAlertsStateContext = React.createContext(
  [] as GrowlAlertsStateContextValue,
)
export const GrowlAlertsActionsContext = React.createContext(
  {} as GrowlAlertsActionsContextValue,
)

// MARK: – GrowlAlertsProvider

export interface GrowlAlertsProviderProps {
  children: React.ReactNode
}

export const GrowlAlertsProvider = ({children}: GrowlAlertsProviderProps) => {
  const [alerts, setAlerts] = useState<Growl[]>([])

  const actionsValue: GrowlAlertsActionsContextValue = useMemo(
    () => ({
      show: (type, payload) =>
        setAlerts((prevAlerts) => [
          ...prevAlerts,
          {
            id: makeShortId(),
            type,
            iconName: {
              success: 'check-circle-fill' as const,
              error: 'prohibit-fill' as const,
              undo: 'info-fill' as const,
            }[type],
            timeout: 3000,
            ...payload,
          },
        ]),
      clear: () => setAlerts([]),
      hide: (alertId) =>
        setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== alertId)),
    }),
    [],
  )

  return (
    <GrowlAlertsActionsContext value={actionsValue}>
      <GrowlAlertsStateContext value={alerts}>
        {children}
      </GrowlAlertsStateContext>
    </GrowlAlertsActionsContext>
  )
}

// MARK: – Hooks

export const useGrowlActions = () => {
  const context = use(GrowlAlertsActionsContext)
  if (context === undefined) {
    throw new Error(
      'useGrowlActions must be used within a `GrowlAlertsProvider`',
    )
  }
  return context
}

export const useGrowlAlerts = () => {
  const context = use(GrowlAlertsStateContext)
  if (context === undefined) {
    throw new Error(
      'useGrowlAlerts must be used within a `GrowlAlertsProvider`',
    )
  }
  return context
}

// MARK: – GrowlAlerts

export const GrowlAlerts: React.FC = () => {
  const alerts = useGrowlAlerts()
  const actions = useGrowlActions()
  const media = useMedia()

  const fixedTop = !media.sm

  const [visibleAlertIds, setVisibleAlertIds] = useState(
    alerts.map((a) => a.id),
  )

  useUpdateEffect(() => {
    setVisibleAlertIds(alerts.map(({id}) => id))
  }, [alerts])

  useEffect(() => {
    const timeouts = alerts
      .filter((a) => a.timeout > 0)
      .map((a) => setTimeout(() => actions.hide(a.id), a.timeout))

    return () => timeouts.forEach((timeout) => clearTimeout(timeout))
  }, [actions, alerts])

  return alerts.length > 0 ? (
    <VStack
      data-placement={fixedTop ? 'top' : 'bottom'}
      className={
        'fixed right-0 z-toast w-full gap-2 data-[placement=top]:top-0 data-[placement=bottom]:bottom-0 sm:w-auto'
      }
    >
      {alerts.map((alert) => (
        <motion.div
          key={alert.id}
          className="sm:mr-3"
          initial={{opacity: 0, y: 50, scale: 0.3}}
          transition={{
            ease: 'linear',
            duration: 0.15,
          }}
          variants={{
            visible: {
              opacity: 1,
              y: 0,
              scale: 1,
            },
            invisible: {
              opacity: 0,
              scale: 0.5,
            },
          }}
          animate={visibleAlertIds.includes(alert.id) ? 'visible' : 'invisible'}
          onAnimationComplete={(animDef) => {
            if (
              !visibleAlertIds.includes(alert.id) &&
              animDef === 'invisible'
            ) {
              actions.hide(alert.id)
            }
          }}
        >
          <GrowlAlert
            alert={alert}
            onHide={() =>
              setVisibleAlertIds((prevVisibleAlertIds) =>
                prevVisibleAlertIds.filter((alertId) => alertId !== alert.id),
              )
            }
          />
        </motion.div>
      ))}
    </VStack>
  ) : null
}

// MARK: – GrowlAlert

export interface GrowlAlertProps {
  alert: Growl
  onHide?: () => void
}

export const GrowlAlert: React.FC<GrowlAlertProps> = ({alert, onHide}) => {
  const iconSize = 26

  const media = useMedia()
  const iconElement = useMemo(
    () =>
      alert.iconName ? (
        <PhosphorIcon
          icon={alert.iconName}
          width={iconSize}
          color={
            alert.type === 'undo'
              ? tailwindConfig.theme.colors.orange[500]
              : '#ffffff'
          }
        />
      ) : null,
    [alert.type, alert.iconName],
  )

  return (
    <VStack
      data-type={alert.type}
      className={`relative w-full bg-tint shadow-z2 data-[type="undo"]:bg-gray750 sm:w-[440px] sm:rounded`}
    >
      <HStack className="shrink-0 grow basis-auto items-center gap-3 p-4 text-trueWhite">
        {iconElement &&
          media.sm &&
          (alert.type === 'undo' ? (
            iconElement
          ) : (
            <HStack
              className="shrink-0 items-center justify-center rounded-full bg-orange-500"
              style={{
                width: iconSize + 10,
                height: iconSize + 10,
              }}
            >
              {iconElement}
            </HStack>
          ))}

        <VStack className="flex-[1] gap-1">
          {'title' in alert && alert.title && (
            <Heading as="h4">{alert.title}</Heading>
          )}
          {!!alert.body && (
            <Text className="font-light text-ds-sm">{alert.body}</Text>
          )}
        </VStack>

        {alert.type === 'undo' && (
          <Button
            className="text-teal-70"
            variant="text"
            onClick={() => {
              if ('onUndo' in alert) {
                alert.onUndo?.()
              }
              onHide?.()
            }}
          >
            Undo
          </Button>
        )}

        <IconButton
          size="default_alt"
          onClick={() => {
            onHide?.()
            alert.onDidClose?.()
          }}
        >
          <PhosphorIcon icon="x" color="#ffffff" />
        </IconButton>
      </HStack>

      {alert.timeout > 0 && (
        <TimedProgress transitionDuration={alert.timeout / 1000} />
      )}
    </VStack>
  )
}
