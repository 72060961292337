import {makeAsComponent} from '@cheddarup/react-util'
import React from 'react'

import {Ellipsis} from './Ellipsis'
import {cn} from '../utils'

export interface AnchorProps {
  iconBefore?: React.ReactNode
  iconAfter?: React.ReactNode
}

export const Anchor = makeAsComponent<'a', AnchorProps>(
  ({
    as: Comp = 'a',
    iconBefore,
    iconAfter,
    className,
    children,
    ...restProps
  }) => (
    <Comp
      className={cn(
        'Anchor relative inline-flex flex-row items-center justify-center whitespace-nowrap text-tint transition-colors duration-100 ease-in-out hover:text-anchorHoverText focus:outline-none',
        className,
      )}
      {...restProps}
    >
      {iconBefore && (
        <div className="Anchor-iconBefore mr-2 flex flex-0 items-center justify-center">
          {iconBefore}
        </div>
      )}

      {typeof children === 'string' ? (
        <Ellipsis className="Anchor-content flex-[1]">{children}</Ellipsis>
      ) : (
        children
      )}

      {iconAfter && (
        <div className="Anchor-iconAfter ml-2 flex flex-0 items-center justify-center">
          {iconAfter}
        </div>
      )}
    </Comp>
  ),
)
