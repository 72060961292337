import {makeAsComponent} from '@cheddarup/react-util'

import {cn} from '../utils'

export type StackDirection = 'vertical' | 'horizontal'

export interface StackProps {
  direction?: StackDirection
}

// Based on https://every-layout.dev/layouts/stack/
/**
 * @deprecated Use native html instead
 */
export const Stack = makeAsComponent<'div', StackProps>(
  ({as: Comp = 'div', className, direction = 'horizontal', ...restProps}) => (
    <Comp
      className={cn(
        `Stack Stack--${direction}`,
        'flex',
        direction === 'vertical' ? 'flex-col' : 'flex-row',
        className,
      )}
      {...restProps}
    />
  ),
)

// MARK: - Convenience components

export interface HStackProps extends Omit<StackProps, 'direction'> {}

/**
 * @deprecated Use native html instead
 */
export const HStack = makeAsComponent<'div', HStackProps>((props) => (
  <Stack direction="horizontal" {...props} />
))

export interface VStackProps extends Omit<StackProps, 'direction'> {}

/**
 * @deprecated Use native html instead
 */
export const VStack = makeAsComponent<'div', VStackProps>((props) => (
  <Stack direction="vertical" {...props} />
))
