import {z} from '@cheddarup/util'
import {emailStatusSchema} from './tabs'
import {s3ImageSchema} from './common'

export const participantOptionsSchema = z.object({
  aboutMeText: z.string(),
  thankYouEmailText: z.string(),
  hideDonors: z.boolean(),
  donationGoal: z.number(),
  activityGoal: z.number(),
})

export const supporterFeedSchema = z.object({
  message: z.string(),
  timestamp: z.string(),
  total: z.number(),
  donor_name: z.string(),
  participant_id: z.number(),
})

export const leaderboardParticipantItemSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  totalCollected: z.number(),
  donations: z.number(),
  profile_picture: s3ImageSchema.optional(),
  profile_picture_id: z.number().nullable(),
  team: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
  team_id: z.number().nullable(),
})

export const leaderboardTeamItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  participants: z.number(),
  donations: z.number(),
  totalCollected: z.number(),
})

export const participantSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email(),
  team_id: z.number().nullable(),
  invite_email_status: emailStatusSchema.nullable(),
  invite_sent_at: z.string().nullable(),
  options: participantOptionsSchema,
  team: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
  donations: z.number(),
  totalCollected: z.number(),
  archived: z.boolean(),
})

export const participantSuggestionSchema = participantSchema.pick({
  id: true,
  email: true,
  first_name: true,
  last_name: true,
  team: true,
})

export const publicParticipantSchema = participantSchema
  .pick({
    id: true,
    first_name: true,
    last_name: true,
    email: true,
    team_id: true,
    team: true,
    invite_sent_at: true,
    invite_email_status: true,
    archived: true,
  })
  .extend({
    created_at: z.string(),
    updated_at: z.string(),
    tab_id: z.number(),
    uuid: z.string(),
    self_signed_up: z.boolean(),
    invited: z.boolean(),
    profile_picture: s3ImageSchema.optional(),
    profile_picture_id: z.number().nullable(),
    personal_video_id: z.number().nullable(),
    personal_video: s3ImageSchema
      .pick({id: true, upload_path: true, metadata: true})
      .extend({s3_url: z.string()})
      .optional(),
    options: z
      .object({
        aboutMeText: z.string(),
        thankYouEmailText: z.string(),
        hideDonors: z.boolean(),
        donationGoal: z.number(),
        activityGoal: z.number(),
      })
      .partial(),
    totalCollected: z.number(),
  })

declare global {
  namespace Api {
    // @ts-ignore
    type FundraiserParticipant = z.infer<typeof participantSchema>
    // @ts-ignore
    type PublicParticipant = z.infer<typeof publicParticipantSchema>
    // @ts-ignore
    type ParticipantSuggestion = z.infer<typeof participantSuggestionSchema>
    // @ts-ignore
    type SupporterFeed = z.infer<typeof supporterFeedSchema>
    // @ts-ignore
    type LeaderboardParticipantItem = z.infer<
      typeof leaderboardParticipantItemSchema
    >
    // @ts-ignore
    type LeaderboardTeamItem = z.infer<typeof leaderboardTeamItemSchema>
  }
}
