import type {EmptyObject, SimpleMerge} from '@cheddarup/util'
import React, {type JSX} from 'react'
import reactFastCompare from 'react-fast-compare'
import flattenChildren from 'react-keyed-flatten-children'

export const reactDeepEqual = reactFastCompare

/**
 * Passthrough function used to type a full-fledged generic React component
 * based on a generic function
 */
export function generic<T, P = T extends (props: infer _P) => any ? _P : never>(
  Component: T,
) {
  return Component as any as React.ComponentType<P> & T
}

/** Generic version of React.forwardRef */
export const genericForwardRef = <
  R extends React.ForwardRefRenderFunction<any, any>,
  T = R extends React.ForwardRefRenderFunction<infer _T, any> ? _T : never,
  P = R extends React.ForwardRefRenderFunction<any, infer _P> ? _P : never,
>(
  render: R,
) =>
  React.forwardRef<T, P>(render) as React.ForwardRefExoticComponent<
    React.PropsWithoutRef<P> & {ref?: React.Ref<T>}
  > &
    R

/** Generic version of React.memo */
export const genericMemo = <
  C,
  P = C extends (props: infer _P) => any ? _P : never,
>(
  Component: C,
  propsAreEqual?: (prevProps: Readonly<P>, nextProps: Readonly<P>) => boolean,
) =>
  React.memo(
    Component as any,
    propsAreEqual,
  ) as any as React.MemoExoticComponent<React.ComponentType<P>> & C

type NamedExoticComponent<E, TOwnProps> = React.NamedExoticComponent<
  SimpleMerge<
    E extends React.ElementType ? React.ComponentProps<E> : never,
    TOwnProps & {as?: E}
  >
>

/* -------------------------------------------------------------------------------------------------
 * AsComponent
 * -----------------------------------------------------------------------------------------------*/

export interface AsComponent<IntrinsicElementString, TOwnProps = EmptyObject>
  extends NamedExoticComponent<IntrinsicElementString, TOwnProps> {
  <As = IntrinsicElementString>(
    props: As extends ''
      ? {as: keyof React.JSX.IntrinsicElements}
      : As extends React.JSXElementConstructor<infer P>
        ? SimpleMerge<P, TOwnProps & {as: As}>
        : As extends keyof JSX.IntrinsicElements
          ? SimpleMerge<React.JSX.IntrinsicElements[As], TOwnProps & {as: As}>
          : never,
  ): React.ReactNode
}

export function makeAsComponent<IntrinsicElementString, TOwnProps>(
  render: (
    render: React.ComponentProps<
      AsComponent<IntrinsicElementString, TOwnProps>
    >,
  ) => React.ReactNode,
) {
  return render as AsComponent<IntrinsicElementString, TOwnProps>
}

// Based on https://github.com/udecode/plate/blob/main/packages/core/src/utils/react/withProps.tsx

export const withProps: <T extends object, U = T>(
  Component: React.ComponentType<T>,
  props: Partial<T> | ((passedProps: T & U) => Partial<T>),
) => React.FunctionComponent<T & U> = (Component, props) => (_props) => {
  const staticProps = typeof props === 'function' ? props(_props) : props

  return (
    <Component
      {...staticProps}
      {..._props}
      {...('className' in _props &&
      typeof _props.className === 'string' &&
      'className' in staticProps &&
      typeof staticProps.className === 'string'
        ? {className: `${staticProps.className} ${_props.className}`}
        : undefined)}
    />
  )
}

// Based on https://github.com/fernandopasik/react-children-utilities/blob/main/src/lib/onlyText.ts

export function hasChildren(
  element: React.ReactNode,
): element is React.ReactElement<{
  children: React.ReactNode
}> {
  return React.isValidElement(element) && !!(element.props as any).children
}

export function childToString(child: React.ReactNode) {
  if (child == null || typeof child === 'boolean') {
    return ''
  }

  if (JSON.stringify(child) === '{}') {
    return ''
  }

  return (child as number | string).toString()
}

export function getStringFromChildren(
  children: React.ReactNode,
  joiner = '',
): string {
  return flattenChildren(children).reduce<string>(
    (text, child: React.ReactNode) => {
      if (React.isValidElement(child)) {
        return text.concat(
          hasChildren(child) ? getStringFromChildren(child.props.children) : '',
          joiner,
        )
      }

      return text.concat(childToString(child), joiner)
    },
    '',
  )
}
