import {makeAsComponent} from '@cheddarup/react-util'
import React, {use, useState} from 'react'

import {Button} from './Button'
import {DialogInstance} from './Dialog'
import {HStack, VStack, VStackProps} from './Stack'
import {Modal, ModalCloseButton, ModalHeader, ModalProps} from './Modal'
import {Text} from './Text'
import {cn} from '../utils'

interface InternalAlertContextValue extends DialogInstance {}

const InternalAlertContext = React.createContext(
  {} as InternalAlertContextValue,
)

// MARK: – Alert

export interface AlertProps extends ModalProps {
  closeButtonVisible?: boolean
}

export const Alert = ({
  className,
  initialVisible = false,
  closeButtonVisible = true,
  role = 'alertdialog',
  children,
  ...restProps
}: AlertProps) => (
  <Modal
    className={cn('Alert sm:[&_>_.ModalContentView]:max-w-[480px]', className)}
    initialVisible={initialVisible}
    role={role}
    {...restProps}
  >
    {(dialog) => (
      <InternalAlertContext value={dialog}>
        {typeof children === 'function' ? children(dialog) : children}
        {closeButtonVisible && (
          <ModalCloseButton className="Alert-closeButton" />
        )}
      </InternalAlertContext>
    )}
  </Modal>
)

// MARK: – AlertHeader

export interface AlertHeaderProps {}

export const AlertHeader = makeAsComponent<'div', AlertHeaderProps>(
  ({className, ...restProps}) => (
    <ModalHeader
      className={cn(
        'AlertHeader [&.ModalHeader]:p-6 [&.ModalHeader]:pr-[calc(theme(spacing.6)+theme(spacing.8))] [&_>_.ModalHeader-content]:font-black [&_>_.ModalHeader-content]:text-ds-base',
        className,
      )}
      {...restProps}
    />
  ),
)

// MARK: – AlertContentView

export interface AlertContentViewProps
  extends VStackProps,
    React.ComponentProps<'div'> {
  text: React.ReactNode
  actions?: React.ReactNode
}

export const AlertContentView = ({
  text,
  actions,
  className,
  ...restProps
}: AlertContentViewProps) => (
  <VStack
    className={cn('AlertContentView', 'gap-7 p-7', className)}
    {...restProps}
  >
    <Text className="AlertContentView-text font-light text-gray800">
      {text}
    </Text>
    {!!actions && (
      <HStack className="AlertContentView-actionBar gap-3">{actions}</HStack>
    )}
  </VStack>
)

// MARK: – AlertActionButton

export interface AlertActionButtonProps<TExecuteRes> {
  execute?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<TExecuteRes> | any
  onDidExecute?: (res: TExecuteRes) => void
}

export const AlertActionButton = makeAsComponent<
  typeof Button,
  AlertActionButtonProps<any>
>(
  ({
    as: Comp = Button,
    className,
    variant = 'primary',
    execute,
    onClick,
    onDidExecute,
    ...restProps
  }) => {
    const dialog = use(InternalAlertContext)
    const [loading, setLoading] = useState(false)
    return (
      <Comp
        className={cn('AlertActionButton', className)}
        variant={variant}
        loading={loading}
        onClick={async (event) => {
          onClick?.(event)

          if (!execute || event.defaultPrevented) {
            return
          }

          try {
            setLoading(true)
            const res = await execute(event)
            onDidExecute?.(res)
            dialog.hide()
          } finally {
            setLoading(false)
          }
        }}
        {...restProps}
      />
    )
  },
)

// MARK: – AlertCancelButton

export interface AlertCancelButtonProps {}

export const AlertCancelButton = makeAsComponent<
  typeof Button,
  AlertCancelButtonProps
>(
  ({
    as: Comp = Button,
    className,
    variant = 'secondary',
    onClick,
    children = 'Cancel',
    ...restProps
  }) => {
    const dialog = use(InternalAlertContext)
    return (
      <Comp
        className={cn('AlertCancelButton', className)}
        variant={variant}
        onClick={(event) => {
          onClick?.(event)
          if (!event.defaultPrevented) {
            dialog.hide()
          }
        }}
        {...restProps}
      >
        {children}
      </Comp>
    )
  },
)
