import {
  QueryClient,
  QueryClientProvider,
  QueryClientProviderProps,
} from '@tanstack/react-query'
import {SetOptional} from '@cheddarup/util'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60000,
      refetchOnWindowFocus: false,
    },
  },
})

export interface QueryProviderProps
  extends SetOptional<QueryClientProviderProps, 'client'> {}

export const QueryProvider = (props: QueryProviderProps) => {
  return <QueryClientProvider client={queryClient} {...props} />
}
