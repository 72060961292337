// Based on https://github.com/seek-oss/braid-design-system/blob/master/lib/components/Loader/Loader.tsx

import {cn} from '../utils'

export type LoaderVariant = 'default' | 'light'

export interface LoaderProps extends React.ComponentProps<'svg'> {
  size?: React.CSSProperties['width'] | React.CSSProperties['height']
  variant?: LoaderVariant
  'aria-label'?: string
  delayVisibility?: boolean
}

export const Loader = ({
  size = '3.5em',
  variant = 'default',
  'aria-label': ariaLabel = 'Loading',
  delayVisibility = true,
  className,
  style,
  ...restProps
}: LoaderProps) => (
  <svg
    aria-hidden
    xmlns="http://www.w3.org/2000/svg"
    data-visibility-delayed={delayVisibility}
    className={cn(
      `Loader Loader--${variant}`,
      'data-[visibility-delayed]:animate-fade data-[visibility-delayed]:opacity-0',
      '[&_>_circle]:animate-pulse-alt [&_>_circle]:[transform-box:fill-box] [&_>_circle]:[transform-origin:center_center]',
      '[&_>_circle:nth-child(2)]:[animation-delay:250ms] [&_>_circle:nth-child(3)]:[animation-delay:500ms]',
      variant === 'default' && 'fill-depr-grey-400',
      variant === 'light' && 'fill-trueWhite',
      className,
    )}
    style={{
      width: size,
      height: size,
      ...style,
    }}
    viewBox="0 0 300 134"
    {...restProps}
  >
    <title>Loader</title>

    <circle cy="67" cx="40" r="40" />
    <circle cy="67" cx="150" r="40" />
    <circle cy="67" cx="260" r="40" />
  </svg>
)
