import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {
  groupPageSchema,
  paginationSchema,
  publicParticipantSchema,
  publicTabSchema,
  publicTabTeamSummarySchema,
  tabTeamSchema,
} from '../schemas'

export default {
  detail: makeEndpoint({
    path: 'collections/:tabId',
    queryParamsSchema: z
      .object({
        name: z.string(),
        email: z.string(),
        access_code: z.string(),
        posCode: z.string(),
      })
      .partial()
      .optional(),
    responseSchema: publicTabSchema,
  }),
  home: makeEndpoint({
    path: 'collections/:tabId/home',
    responseSchema: groupPageSchema,
  }),
  contact: makeEndpoint({
    path: 'collections/:tabId/contact',
    method: 'POST',
    bodySchema: z.object({
      name: z.string(),
      email: z.string(),
      message: z.string(),
      captchaToken: z.string().optional(),
    }),
    responseSchema: z.any(),
  }),
  listTeams: makeEndpoint({
    path: 'collections/:tabId/teams',
    responseSchema: tabTeamSchema.array(),
  }),
  listTeamSummaries: makeEndpoint({
    path: 'collections/:tabId/teams_summary',
    responseSchema: publicTabTeamSummarySchema.array(),
  }),
  participants: makeEndpoint({
    path: 'collections/:tabId/participants',
    queryParamsSchema: z
      .object({
        page: z.number(),
        per_page: z.number(),
        name: z.string(),
        team_ids: z.number().array(),
        sort: z.enum([
          'total_collected',
          'name',
          'first_name',
          'last_name',
          'team',
        ]),
        direction: z.enum(['asc', 'desc']),
      })
      .partial()
      .optional(),
    responseSchema: z.object({
      data: publicParticipantSchema.array(),
      pagination: paginationSchema,
    }),
  }),
  participant: makeEndpoint({
    path: 'collections/:tabId/participant/:participantId',
    responseSchema: publicParticipantSchema,
  }),
}
