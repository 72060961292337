import * as Util from '@cheddarup/util'

import {apiClient} from './client'

export const handleGetSession = (session: Api.Session | undefined) => {
  setTrackingData(session)
  if (session?.user) {
    trackGAEvent(session, 'GET_SESSION')
    identifyViaUpvoty(session)
    identifyViaConvertflow(session)
    identifyViaZendesk(session)
  }
}

export const handlePostLogin = (session: Api.Session) => {
  trackGAEvent(session, 'LOGGED_IN')
  identifyViaConvertflow(session)
  identifyViaUpvoty(session)
  setTrackingData(session)
}

export const handlePostSignup = (session: Api.Session) => {
  trackGAEvent(session, 'SIGNED_UP')
  identifyViaConvertflow(session)
  identifyViaUpvoty(session)
  setTrackingData(session)
}

export const handleLogout = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({event: 'LOGGED_OUT', userId: null})
  identifyViaUpvoty(undefined)
  identifyViaConvertflow(undefined)
  setTrackingData(null)
}

export const handlePostPaymentMethods = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({event: 'PAYMENT_METHOD_CHANGED'})
}

// MARK: – Helpers

export const setTrackingData = (session: Api.Session | null | undefined) => {
  window.trackcmp_email = session?.user?.email ?? null
  window.trackcmp_id = session?.user?.id ?? null

  if (session?.analytics?.cflow && !window.convertflow) {
    try {
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://js.convertflow.co/production/websites/11117.js'
      script.type = 'text/javascript'
      document.querySelectorAll('head')[0]?.append(script)
    } catch (e) {
      console.error(e)
    }
  }

  if (window.clarity) {
    try {
      window.clarity('set', 'BRANCH', window.netlify_ab_branch)
    } catch (e) {
      console.error(e)
    }
  }

  if (session?.user) {
    const mediaQuery = window.matchMedia?.('(max-width: 959px)')

    if (window._mfq) {
      try {
        window._mfq.push(
          ['identify', window.trackcmp_email],
          ['setVariable', 'userId', window.trackcmp_id],
        )
      } catch (e) {
        console.error(e)
      }
    }

    if (window.clarity) {
      try {
        window.clarity('identify', session.user.id.toString())
        Util.keys(session.user)
          .filter((i) =>
            [
              'created_at',
              'updated_at',
              'display_name',
              'slug',
              'uuid',
              'id',
              'email',
              'first_name',
              'last_name',
              'entity_type',
            ].includes(i),
          )
          .forEach((userKey) => {
            window.clarity?.(
              'set',
              userKey,
              (session.user[userKey] || '').toString(),
            )
          })
        window.clarity('set', 'partner', session.organization || '')
        window.clarity('set', 'isMobile', mediaQuery?.matches)
      } catch (e) {
        console.error(e)
      }
    }

    if (window.NickelledLaunchers) {
      try {
        window.NickelledLaunchers.setUser({
          appId: 'cheddarup.com-328331',
          userId: session.user.id,
          ...session.capabilities,
          ...session.user,
        })
      } catch (e) {
        console.error(e)
      }
    }
    if (window.hj) {
      try {
        window.hj('identify', session.user.id, {
          ...session.user,
          ...session.capabilities,
          partner: session.organization,
        })
      } catch (e) {
        console.error(e)
      }
    }
  }

  if (window.Upscope) {
    try {
      window.Upscope('updateConnection', {
        identities: session?.user
          ? [session.user.email, session.user.full_name]
          : null,
        uniqueId: session?.user?.id ?? null,
        tags: session?.user
          ? [session.organization_data?.name ?? null]
          : ['Guest'],
      })
    } catch (e) {
      console.error(e)
    }
  }

  if (window.clarity) {
    try {
      window.clarity('set', 'isGuest', !session?.user)
    } catch (e) {
      console.error(e)
    }
  }

  window.zESettings = window.zESettings || {}
  window.zESettings.webWidget = window.zESettings.webWidget || {}
  window.zESettings.webWidget.authenticate = {
    chat: {
      jwtFn: async (callback) => {
        try {
          const {data: jwt} = await apiClient.get('zendesk')
          callback(jwt)
        } catch {
          // noop
        }
      },
    },
  }
}

const identifyViaUpvoty = (session: Api.Session | undefined) => {
  if (typeof window === 'undefined') {
    return
  }

  try {
    window.Upvoty?.authenticate(session?.upvoty?.token)
  } catch (err) {
    console.error(err)
  }
}
const identifyViaConvertflow = (session: Api.Session | undefined) => {
  try {
    window.convertflow?.identify({
      email: session?.user?.email,
      override: true,
    })
    if (!window.convertflow?.person?.email) {
      window.convertflow?.app.visitors.get()
    }
  } catch (err) {
    console.error(err)
  }
}

const identifyViaZendesk = (session: Api.Session | undefined) => {
  try {
    if (session) {
      window.zE?.('webWidget', 'identify', {
        email: session.user.email,
        name: session.user.full_name,
        session: session.organization,
      })
      window.zE?.('webWidget', 'prefill', {
        email: {
          value: session.user.email,
          readonly: true,
        },
        name: {
          value: session.user.full_name,
          readonly: true,
        },
      })
    } else {
      window.zE?.('webWidget', 'logout')
    }
  } catch (err) {
    console.error(err)
  }
}

const trackGAEvent = (session: Api.Session, event: string) => {
  try {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event,
      userId: session.user.id,
      email: session.user.email,
      partner: session.organization,
      customer_category: session.user.customer_category,
      ...Util.omit(session.analytics?.sib, [
        'bestdescribesyou',
        'firstname',
        'lastname',
        'name',
      ]),
    })
  } catch (err) {
    console.error(err)
  }
}

export const trackPurchaseEvent = (
  cart: Api.Cart,
  tab: Api.PublicTab,
  paymentMethod: string | undefined,
) => {
  try {
    const cartId = cart.uuid.split('!!')[1]
    const items = cart.items.map((item) => ({
      item_id: item.tab_item.id,
      item_name: item.tab_item.name,
      affiliation: tab.slug,
      currency: tab.organizer.currency,
      price: item.amount,
      quantity: item.quantity,
    }))

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'PURCHASE',
      affiliation: tab.slug,
      coupon: Object.keys(cart.discounts).join('|'),
      discount: cart.totalDiscount,
      currency: tab.organizer.currency,
      transaction_id: cartId,
      shipping: cart.shippingInfo.shippingMethod
        ? (cart.shipping[cart.shippingInfo.shippingMethod] ?? 0)
        : 0,
      tax: cart.totalTaxes,
      value: cart.total,
      cart_id: cartId,
      payment_type: paymentMethod,
      platform_fees: tab.cZoala,
      collection_id: tab.id,
      collection_category: tab.collectionCategory,
      collection_subcategory: tab.collectionSubcategory?.join('|'),
      collection_purpose: tab.collectionPurpose,
      collector_user_id: tab.organizer.id,
      collector_name: tab.organizer.displayName,
      collector_customer_category: tab.organizer.customerCategory,
      items,
    })
  } catch {
    // nothing
  }
}

export const trackPurchaseSubscriptionEvent = (
  discountData: any,
  subscribedPlan: any,
  paymentMethod: string,
  currency = 'USD',
) => {
  try {
    window.dataLayer = window.dataLayer ?? []
    window.dataLayer.push({
      event: 'PURCHASE_SUBSCRIPTION',
      affiliation: 'Cheddar Up Subscription',
      coupon: discountData?.coupon,
      discount: discountData?.discount,
      currency,
      transaction_id: subscribedPlan.subscriptionId,
      value: discountData?.total || subscribedPlan.newPlanAmount,
      payment_type: paymentMethod,
      old_plan_sku: subscribedPlan.oldPlanSku,
      old_plan_name: subscribedPlan.oldPlanName,
      old_plan_amount: subscribedPlan.oldPlanAmount,
      new_plan_sku: subscribedPlan.newPlanSku,
      new_plan_name: subscribedPlan.newPlan,
      new_plan_amount: subscribedPlan.newPlanAmount,
      items: [
        {
          item_id: subscribedPlan.newPlanSku,
          item_name: subscribedPlan.newPlan,
          affiliation: 'Cheddar Up Subscription',
          currency,
          price: subscribedPlan.newPlanAmount,
          quantity: 1,
        },
      ],
    })
  } catch {
    // nothing
  }
}

export const trackUserSubscriptionChangeEvent = (
  plan: any,
  discountData: any = null,
) => {
  try {
    window.dataLayer = window.dataLayer ?? []
    window.dataLayer.push({
      event: 'USER_SUBSCRIPTION_CHANGE',
      new_plan_name: plan.newPlan,
      old_plan_name: plan.oldPlanName,
      old_plan_sku: plan.oldPlanSku,
      new_plan_sku: plan.newPlanSku,
      old_plan_amount: plan.oldPlanAmount,
      new_plan_amount: plan.newPlanAmount,
      coupon: discountData?.coupon,
      discount: discountData?.discount,
      value: discountData?.total || plan.newPlanAmount,
    })
  } catch {
    // nothing
  }
}

export const generalCollectionTracking = (collection: Api.PublicTab) => {
  try {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      collection_id: collection.id,
      collection_slug: collection.slug,
      collection_purpose: collection.collectionPurpose,
      collection_category: collection.collectionCategory,
      collection_subcategory: collection.collectionSubcategory?.join('|'),
      collector_user_id: collection.organizer.id,
      collector_name: collection.organizer.displayName,
      collector_customer_category: collection.organizer.customerCategory,
    })
  } catch {
    // nothing
  }
}

export const shareEventTracking = (
  collection: Api.Tab | Api.PublicTab,
  method: string,
  contentType: string,
  recipientCount: number | null = null,
) => {
  try {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      method,
      content_type: contentType,
      collection_id: collection.id,
      collection_slug: collection.slug,
      collection_purpose:
        'collection_purpose' in collection
          ? collection.collection_purpose
          : collection.collectionPurpose,
      collection_category:
        'collection_category' in collection
          ? collection.collection_category
          : collection.collectionCategory,
      collection_subcategory:
        'collection_subcategory' in collection
          ? collection.collection_subcategory?.join('|')
          : 'collectionSubcategory' in collection
            ? collection.collectionSubcategory?.join('|')
            : null,
      collector_user_id: collection.organizer.id,
      collector_name: collection.organizer.displayName,
      collector_customer_category: collection.organizer.customerCategory,
      recipient_count: recipientCount,
    })
  } catch {
    // nothing
  }
}
