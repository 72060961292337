import {z} from '@cheddarup/util'

import {makeEndpoint} from '../utils'
import {s3ImageMetadataSchema, s3ImageSchema} from '../schemas'

export default {
  createSignedUploadUrl: makeEndpoint({
    path: ':parentPath/signed_upload_url',
    method: 'POST',
    bodySchema: z
      .object({
        objectName: z.string(),
        metadata: s3ImageMetadataSchema,
      })
      .passthrough(),
    responseSchema: z.object({
      signedUrl: z.string(),
      uploadPath: z.string(),
      editedImageSignedUrl: z.string(),
      editedImageUploadPath: z.string(),
    }),
  }),
  createImageRecord: makeEndpoint({
    path: ':parentPath/create_image_record',
    method: 'POST',
    bodySchema: z.object({
      objectName: z.string(),
      upload_path: z.string(),
      metadata: s3ImageMetadataSchema,
    }),
    responseSchema: s3ImageSchema,
  }),
  updateImageRecord: makeEndpoint({
    path: ':parentPath/update_image_record/:imageId',
    method: 'PATCH',
    bodySchema: z.object({
      metadata: s3ImageMetadataSchema.partial(),
    }),
    responseSchema: s3ImageSchema,
  }),
  // TODO: replace other `delete_image` endpoints with this one
  // we have a bunch of them
  deleteImageRecord: makeEndpoint({
    path: ':parentPath/delete_image/:imageId',
    method: 'DELETE',
    responseSchema: z.any(),
  }),
}
