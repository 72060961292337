import * as Ariakit from '@ariakit/react'
import React, {useState} from 'react'
import {cva} from 'class-variance-authority'

import {Loader} from '../Loader'
import {cn, VariantsProps} from '../../utils'
import {Tooltip, TooltipAnchor, TooltipContent, TooltipProps} from '../Tooltip'
import {getStringFromChildren} from '@cheddarup/react-util'
import {NextAnchor, nextAnchor, NextAnchorProps} from './Anchor'

export const nextButton = cva(
  [
    'group/button relative inline-flex min-w-max cursor-pointer select-none appearance-none flex-row items-center justify-center gap-2 overflow-hidden outline-none transition-colors',
    'text-ellipsis whitespace-nowrap text-center font-body font-normal [&[type="submit"]]:font-black',
    'aria-disabled:cursor-not-allowed',
  ],
  {
    variants: {
      variant: {
        // TODO: add a `ghost` variant
        teal: [
          'bg-teal-600',
          'text-trueWhite',
          'hover:bg-teal-700',
          'data-[focus-visible=true]:ring-3 data-[focus-visible=true]:ring-teal-100',
          'aria-disabled:bg-teal-300',
        ],
        orange: [
          'bg-orange-500',
          'text-trueWhite',
          'hover:bg-orange-600',
          'data-[focus-visible=true]:ring-3 data-[focus-visible=true]:ring-orange-300',
          'aria-disabled:bg-orange-300',
        ],
        violet: [
          'bg-violet-500',
          'text-trueWhite',
          'hover:bg-violet-600',
          'data-[focus-visible=true]:ring-3 data-[focus-visible=true]:ring-violet-300',
          'aria-disabled:bg-violet-300',
        ],
        outlined: [
          'border border-grey-400 bg-trueWhite',
          'text-grey-700',
          'data-[focus-visible=true]:ring-3 data-[focus-visible=true]:ring-grey-400',
          'aria-disabled:text-grey-400',
        ],
        gray: [
          'bg-grey-200',
          'text-grey-700',
          'hover:bg-grey-100',
          'data-[focus-visible=true]:ring-3 data-[focus-visible=true]:ring-grey-400',
          'aria-disabled:bg-grey-200 aria-disabled:text-grey-400',
        ],
        transparent: [
          'bg-transparent',
          'text-grey-600',
          'hover:bg-grey-100/90',
          'data-[focus-visible=true]:ring-3 data-[focus-visible=true]:ring-grey-300 data-[focus-visible=true]:ring-inset',
          'aria-disabled:text-grey-400',
        ],
        headless: '[font-weight:inherit]',
      },
      roundness: {
        none: 'rounded-none',
        default: 'rounded',
        pill: 'rounded-full',
        headless: '',
      },
      size: {
        xs: 'px-3 py-2 text-ds-xs',
        sm: 'px-4 py-2 text-ds-sm',
        md: 'px-5 py-2 text-ds-base',
        lg: 'px-6 py-3 text-ds-md',
        xl: 'px-7 py-3 text-ds-lg',
        headless: '',
      },
      isIconOnly: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        size: ['xs', 'sm', 'md'],
        isIconOnly: true,
        class: 'p-2',
      },
      {
        size: ['lg', 'xl'],
        isIconOnly: true,
        class: 'p-[0.5em]',
      },
    ],
    defaultVariants: {
      variant: 'teal',
      size: 'sm',
    },
  },
)

export interface NextButtonProps
  extends Omit<VariantsProps<typeof nextButton>, 'isIconOnly'>,
    Ariakit.ButtonProps {
  loading?: boolean
}

export const NextButton = ({
  variant = 'teal',
  roundness = 'default',
  size = 'sm',
  loading,
  className,
  disabled,
  children,
  ...restProps
}: NextButtonProps) => {
  const hasStringContent = getStringFromChildren(children).length > 0

  return (
    <Ariakit.Button
      data-loading={loading}
      className={cn(
        nextButton({variant, size, roundness, isIconOnly: !hasStringContent}),
        className,
      )}
      disabled={disabled || loading}
      {...restProps}
    >
      {loading && (
        <div
          aria-label="Loading"
          className="absolute inset-0 inline-flex flex-row items-center justify-center bg-grey-600/70"
        >
          <Loader size="1.75em" variant="light" />
        </div>
      )}
      {children}
    </Ariakit.Button>
  )
}

// MARK: ButtonAnchor

export interface ButtonAnchorProps
  extends VariantsProps<typeof nextAnchor>,
    Ariakit.ButtonOptions,
    React.ComponentProps<'button'> {}

export const ButtonAnchor = ({
  className,
  variant,
  ...restProps
}: ButtonAnchorProps) => (
  <Ariakit.Button
    className={cn(nextAnchor({variant}), className)}
    {...restProps}
  />
)

// MARK: AnchorButton

export interface NextAnchorButtonProps
  extends VariantsProps<typeof nextButton>,
    Ariakit.ButtonOptions,
    Omit<NextAnchorProps, 'variant'> {}

export const NextAnchorButton = ({
  variant,
  size,
  roundness,
  clickOnEnter,
  clickOnSpace,
  disabled,
  autoFocus,
  focusable,
  accessibleWhenDisabled,
  onFocusVisible,
  children,
  ...restProps
}: NextAnchorButtonProps) => (
  <NextButton
    role="link"
    variant={variant}
    size={size}
    roundness={roundness}
    clickOnEnter={clickOnEnter}
    clickOnSpace={clickOnSpace}
    disabled={disabled}
    autoFocus={autoFocus}
    focusable={focusable}
    accessibleWhenDisabled={accessibleWhenDisabled}
    onFocusVisible={onFocusVisible}
    render={<NextAnchor variant="text" disabled={disabled} {...restProps} />}
  >
    {children}
  </NextButton>
)

// MARK: – LabelledButton

export interface LabelledButtonProps extends NextButtonProps {
  tooltipProps?: TooltipProps
  label?: React.ReactNode
}

export const LabelledButton = ({
  tooltipProps,
  label,
  ...restProps
}: LabelledButtonProps) => {
  const button = <NextButton {...restProps} />

  return label ? (
    <Tooltip {...tooltipProps}>
      <TooltipAnchor
        aria-label={getStringFromChildren(label)}
        render={button}
      />
      <TooltipContent>{label}</TooltipContent>
    </Tooltip>
  ) : (
    button
  )
}

// MARK: – LoadingButton

export interface LoadingButtonProps extends NextButtonProps {
  execute?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any
}

export const LoadingButton = ({
  execute,
  onClick,
  ...restProps
}: LoadingButtonProps) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <NextButton
      loading={isLoading}
      onClick={async (event) => {
        onClick?.(event)

        if (!execute || event.defaultPrevented) {
          return
        }

        try {
          setIsLoading(true)
          await execute(event)
        } finally {
          setIsLoading(false)
        }
      }}
      {...restProps}
    />
  )
}
