import {HTMLMotionProps, motion} from 'framer-motion'
import React, {use, useEffect, useRef} from 'react'
import {makeAsComponent} from '@cheddarup/react-util'
import {cva} from 'class-variance-authority'

import {
  Dialog,
  DialogBackdrop,
  DialogContent,
  DialogContentProps,
  DialogInstance,
  DialogProps,
} from './Dialog'
import {IconButton} from './IconButton'
import {Heading} from './Heading'
import {PhosphorIcon, PhosphorIconName} from '../icons'
import {VariantsProps, cn} from '../utils'

interface InternalModalContextValue extends DialogInstance {}

const InternalModalContext = React.createContext(
  {} as InternalModalContextValue,
)

// MARK: – Modal

export type ModalContentViewAppearance =
  | 'default'
  | 'leftToRight'
  | 'rightToLeft'
  | 'overlay'

export interface ModalProps
  extends DialogProps,
    DialogContentProps,
    Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  dialogContentRef?: React.Ref<HTMLDivElement>
  backdropClassName?: string
  disclosure?: React.ReactNode
  contentViewAppearance?: ModalContentViewAppearance
}

export const Modal = ({
  className,
  backdropClassName,
  dialogContentRef,
  contentViewAppearance = 'default',
  disclosure,
  baseId,
  initialVisible = true,
  visible,
  animated = true,
  hideOnClickOutside = true,
  modal,
  onVisibleChange,
  onDidShow,
  onDidHide,
  ref,
  ...restProps
}: ModalProps) => {
  const ContentView = {
    default: ModalContentView,
    leftToRight: SideSheetContentView,
    rightToLeft: SideSheetContentView,
    overlay: OverlayView,
  }[contentViewAppearance]
  const mouseDownRef = useRef<EventTarget | null>(null)

  useEffect(() => {
    const onMouseDown = (event: MouseEvent) => {
      mouseDownRef.current = event.target
    }
    document.addEventListener('mousedown', onMouseDown)
    return () => document.removeEventListener('mousedown', onMouseDown)
  }, [])

  return (
    <Dialog
      ref={ref}
      {...{
        baseId,
        initialVisible,
        visible,
        animated,
        modal,
        onVisibleChange,
        onDidShow,
        onDidHide,
      }}
    >
      {(dialog) => (
        <InternalModalContext value={dialog}>
          {disclosure}

          <DialogBackdrop
            className={cn('Modal-backdrop', backdropClassName)}
            as={ModalBackdropView}
            onClick={(event) => {
              if (hideOnClickOutside && mouseDownRef.current === event.target) {
                dialog.hide()
              }
            }}
          >
            <DialogContent
              ref={dialogContentRef}
              as={ContentView}
              className={cn('Modal-content', className)}
              unstable_autoFocusOnHide={false}
              hideOnClickOutside={false}
              onClick={(event: any) => event.stopPropagation()}
              {...(ContentView === SideSheetContentView
                ? ({appearance: contentViewAppearance} as any)
                : undefined)}
              {...restProps}
            />
          </DialogBackdrop>
        </InternalModalContext>
      )}
    </Dialog>
  )
}

const ModalBackdropView = ({
  className,
  ...restProps
}: React.ComponentProps<'div'>) => {
  const dialog = use(InternalModalContext)
  const props = {
    className: cn(
      'ModalBackdropView',
      'fixed top-0 right-0 bottom-0 left-0 bg-[rgba(51,51,51,0.4)]',
      className,
    ),
    ...restProps,
  }
  return dialog.animated ? (
    <motion.div
      initial={{opacity: 0}}
      animate={
        dialog.visible
          ? {
              opacity: 1,
              transition: {duration: 0.15},
            }
          : {
              opacity: 0,
              transition: {duration: 0.15},
              transitionEnd: {display: 'none'},
            }
      }
      onAnimationComplete={() => setTimeout(() => dialog.stopAnimation(), 0)}
      {...(props as HTMLMotionProps<'div'>)}
    />
  ) : (
    <div {...props} />
  )
}

// MARK: – ModalContentView

const ModalContentView = ({
  className,
  ...restProps
}: React.ComponentProps<'div'>) => {
  const dialog = use(InternalModalContext)

  const innerProps = {
    className:
      'ModalContentView relative max-h-full flex flex-col w-full bg-trueWhite text-contentPrimary overflow-auto focus:outline-none sm:w-4/5 sm:max-h-[calc(100vh-theme(spacing.8))] sm:rounded sm:shadow-z16 sm:overflow-hidden',
    ...restProps,
  }

  return (
    <div
      className={cn(
        'ModalContentView-wrapper',
        'flex h-full bg-trueWhite sm:items-center sm:justify-center sm:bg-[initial]',
        className,
      )}
    >
      {dialog.animated ? (
        <motion.div
          initial={{scale: 0.9, y: '-2rem', opacity: 0}}
          animate={
            dialog.visible
              ? {
                  scale: 1,
                  y: 0,
                  opacity: 1,
                  transition: {ease: 'circOut', duration: 0.15},
                }
              : {
                  scale: 0.9,
                  y: '-2rem',
                  opacity: 0,
                  transition: {ease: 'anticipate', duration: 0.15},
                  transitionEnd: {display: 'none'},
                }
          }
          {...(innerProps as any)}
        />
      ) : (
        <div {...innerProps} />
      )}
    </div>
  )
}

interface SideSheetContentViewProps extends React.ComponentProps<'div'> {
  appearance: Extract<ModalContentViewAppearance, 'leftToRight' | 'rightToLeft'>
}

const SideSheetContentView = ({
  appearance = 'leftToRight',
  className,
  ...restProps
}: SideSheetContentViewProps) => {
  const dialog = use(InternalModalContext)
  const innerProps = {
    className:
      'SideSheetContentView flex flex-col min-w-[300px] max-w-[calc(100vw-theme(spacing.16))] h-full bg-trueWhite text-contentPrimary shadow-z16 overflow-hidden focus:outline-none',
    ...restProps,
  }
  const innerHiddenAnim = {
    x: {
      leftToRight: '-100%',
      rightToLeft: '100vw',
    }[appearance],
  }

  return (
    <div
      className={cn(
        'SideSheetContentView-wrapper',
        'fixed top-0 right-0 bottom-0 left-0 flex',
        // @ts-expect-error
        {rightToLeft: 'justify-end'}[appearance],
        className,
      )}
    >
      {dialog.animated ? (
        <motion.div
          initial={innerHiddenAnim}
          animate={
            dialog.visible
              ? {
                  x: '0',
                  transition: {ease: 'linear', duration: 0.3},
                }
              : {
                  ...innerHiddenAnim,
                  transition: {ease: 'linear', duration: 0.3},
                  transitionEnd: {display: 'none'},
                }
          }
          {...(innerProps as HTMLMotionProps<'div'>)}
        />
      ) : (
        <div {...innerProps} />
      )}
    </div>
  )
}

// MARK: – OverlayView

const OverlayView = ({
  className,
  ...restProps
}: React.ComponentProps<'div'>) => {
  const dialog = use(InternalModalContext)

  const innerProps = {
    className:
      'OverlayView relative h-full w-full flex flex-col bg-trueWhite text-contentPrimary overflow-auto focus:outline-none',
    ...restProps,
  }

  return (
    <div className={cn('ModalContentView-wrapper', 'flex h-full', className)}>
      {dialog.animated ? (
        <motion.div
          initial={{scale: 0.9, y: '-2rem', opacity: 0}}
          animate={
            dialog.visible
              ? {
                  scale: 1,
                  y: 0,
                  opacity: 1,
                  transition: {ease: 'circOut', duration: 0.15},
                }
              : {
                  scale: 0.9,
                  y: '-2rem',
                  opacity: 0,
                  transition: {ease: 'anticipate', duration: 0.15},
                  transitionEnd: {display: 'none'},
                }
          }
          {...(innerProps as any)}
        />
      ) : (
        <div {...innerProps} />
      )}
    </div>
  )
}

// MARK: – ModalCloseButton

export interface ModalCloseButtonProps {
  icon?: PhosphorIconName
}

export const ModalCloseButton = makeAsComponent<
  typeof IconButton,
  ModalCloseButtonProps
>(
  ({
    as: Comp = IconButton,
    size = 'default_alt',
    onClick,
    className,
    children,
    icon = 'x',
    ...restProps
  }) => {
    const modal = use(InternalModalContext)
    return (
      <Comp
        className={cn(
          'ModalCloseButton',
          'absolute top-4 right-4 text-ds-xl',
          className,
        )}
        size={size}
        onClick={(event) => {
          onClick?.(event)
          if (!event.defaultPrevented) {
            modal.hide()
          }
        }}
        {...restProps}
      >
        {children ?? (
          <PhosphorIcon className="ModalCloseButton-icon" icon={icon} />
        )}
      </Comp>
    )
  },
)

// MARK: – ModalHeader

export const modalHeader = cva('', {
  variants: {
    variant: {
      default: [
        `border-b border-grey-300 py-6 pl-9
        pr-[calc(theme(spacing.9)+theme(spacing.8))]`,
      ],
      compact: 'py-6 pr-[calc(theme(spacing.8)+theme(spacing.8)] pl-8',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export type ModalHeaderVariant = 'default' | 'compact'

export interface ModalHeaderProps extends VariantsProps<typeof modalHeader> {}

export const ModalHeader = makeAsComponent<'div', ModalHeaderProps>(
  ({
    as: Comp = 'div',
    variant = 'default',
    className,
    children,
    ...restProps
  }) => (
    <Comp
      className={cn(
        'ModalHeader flex flex-col',
        modalHeader({variant}),
        className,
      )}
      {...restProps}
    >
      {typeof children === 'string' ? (
        <Heading className="ModalHeader-content grow text-gray800" as="h2">
          {children}
        </Heading>
      ) : (
        children
      )}
    </Comp>
  ),
)
