import {Navigate, redirect, To, useLocation} from 'react-router'
import React, {useCallback} from 'react'
import {BooleanParam, StringParam, useQueryParam} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import {
  CookedAuthToken,
  decodeAuthToken,
  isAuthTokenValid,
} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'

const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'auth-token'
const LOCAL_STORAGE_PARTICIPANT_TOKEN_KEY = 'participant-token'

export type TokenType = 'auth' | 'participant'

export function useAuthToken(tokenType: TokenType = 'auth') {
  const [cookedAuthToken, setCookedAuthToken] =
    WebUI.useLocalStorage<CookedAuthToken | null>(
      tokenTypeToLocalStorageKey[tokenType],
      null,
    )

  const setAuthToken = useCallback(
    (
      _authToken:
        | string
        | Util.SetOptional<Api.AuthToken, 'id'>
        | null
        | undefined,
      _token?: string | null,
    ) => {
      let authToken = _authToken
      let token = _token

      if (typeof authToken === 'string') {
        token = authToken
        authToken = decodeAuthToken(authToken)
      }

      setCookedAuthToken(
        authToken && token
          ? {
              ...authToken,
              tokenType: authToken.claims.data.payload.tokenType,
              token,
              expiresAt: authToken.expires_at,
            }
          : null,
      )
    },
    [setCookedAuthToken],
  )

  return [cookedAuthToken, setAuthToken] as const
}

export function useIsAuthed(tokenType?: TokenType) {
  const [authToken] = useAuthToken(tokenType)
  return (
    !!authToken && authToken.tokenType === 'User' && isAuthTokenValid(authToken)
  )
}

export function useAuthHttpHeaders(tokenType: TokenType = 'auth') {
  const [authToken] = useAuthToken(tokenType)

  return {
    Authorization: authToken?.token ? `Bearer ${authToken.token}` : undefined,
  }
}

// MARK: – Helpers

const tokenTypeToLocalStorageKey: Record<TokenType, string> = {
  auth: LOCAL_STORAGE_AUTH_TOKEN_KEY,
  participant: LOCAL_STORAGE_PARTICIPANT_TOKEN_KEY,
}

export function getAuthToken(tokenType: TokenType = 'auth') {
  const authToken = WebUI.readLocalStorageItem<CookedAuthToken | null>(
    tokenTypeToLocalStorageKey[tokenType],
    null,
  )

  return authToken && isAuthTokenValid(authToken) ? authToken : null
}

export function clearAuthToken(tokenType: TokenType = 'auth') {
  localStorage.removeItem(tokenTypeToLocalStorageKey[tokenType])
}

export function getAuthHttpHeaders(tokenType: TokenType = 'auth') {
  const authToken = getAuthToken(tokenType)

  return {
    Authorization: authToken?.token ? `Bearer ${authToken.token}` : undefined,
  }
}

// MARK: – Ensurers

export function ensureAuthLoader(
  request: Request,
  _options?: {
    allowInsecure?: boolean
    requireAuth?: boolean
    redirectTo?: string
    tokenType?: Api.AuthTokenType | 'any'
  },
) {
  const defaultOptions = {
    requireAuth: true,
    tokenType: 'User',
  } as const

  const options = {
    ...defaultOptions,
    ..._options,
  }

  const url = new URL(request.url)
  const preventAuthRedirect =
    url.searchParams.get('preventAuthRedirect') === '1'

  if (preventAuthRedirect) {
    return null
  }

  const authToken = getAuthToken()

  const allowInsecure = options.allowInsecure ?? !options.requireAuth
  const redirectParam = url.searchParams.get('redirect')
  const defaultRedirectTo =
    authToken?.tokenType === 'TabMember'
      ? '/guest/payments'
      : options.requireAuth
        ? '/login'
        : '/collections'

  const [redirectTo, redirectToSearchParams] = (
    redirectParam ??
    options.redirectTo ??
    defaultRedirectTo
  ).split('?')

  if (options.requireAuth) {
    if (
      !authToken ||
      (!allowInsecure && authToken.claims?.insecure) ||
      (options.tokenType !== 'any' && authToken.tokenType !== options.tokenType)
    ) {
      const redirectSearchParams = new URLSearchParams(redirectToSearchParams)

      url.searchParams.forEach((val, key) => {
        redirectSearchParams.set(key, val)
      })

      return redirect(`${redirectTo}?${redirectSearchParams.toString()}`)
    }
  } else {
    if (authToken && (!options.allowInsecure || !authToken?.claims?.insecure)) {
      return redirect(`${redirectTo}${url.search}`)
    }
  }

  return null
}

export interface EnsureAuthProps {
  allowInsecure?: boolean
  requireAuth?: boolean
  redirectTo?: string
  tokenType?: Api.AuthTokenType | 'any'
  children: React.ReactNode
}

export const EnsureAuth: React.FC<EnsureAuthProps> = ({
  allowInsecure: allowInsecureProp,
  requireAuth = true,
  redirectTo: _redirectTo,
  tokenType = 'User',
  children,
}) => {
  const location = useLocation()
  const [preventAuthRedirect] = useQueryParam(
    'preventAuthRedirect',
    BooleanParam,
  )
  const [redirectParam] = useQueryParam('redirect', StringParam)
  const [redirectUriParam] = useQueryParam('redirect_uri', StringParam)
  const [authToken] = useAuthToken()

  const authTokenValid = !!authToken && isAuthTokenValid(authToken)

  const allowInsecure = allowInsecureProp ?? !requireAuth
  const defaultRedirectTo =
    authToken?.tokenType === 'TabMember'
      ? '/guest/payments'
      : requireAuth
        ? '/login'
        : '/collections'

  const [redirectTo, redirectToQueryParams] = (
    redirectUriParam ??
    redirectParam ??
    _redirectTo ??
    defaultRedirectTo
  ).split('?')

  if (!preventAuthRedirect) {
    if (requireAuth) {
      if (
        !authTokenValid ||
        (!allowInsecure && authToken.claims?.insecure) ||
        (tokenType !== 'any' && authToken.tokenType !== tokenType)
      ) {
        const nextRedirect = `${location.pathname}${location.search}`

        return (
          <Navigate
            to={{
              pathname: redirectTo,
              search: Util.mergeSearchParams(
                location.search,
                `redirect=${nextRedirect}${
                  redirectToQueryParams ? `&${redirectToQueryParams}` : ''
                }`,
              ),
            }}
          />
        )
      }
    } else {
      if (authTokenValid && (!allowInsecure || !authToken?.claims?.insecure)) {
        return <Navigate to={{pathname: redirectTo, search: location.search}} />
      }
    }
  }

  return <>{children}</>
}

export interface EnsureTabMemberAuthProps {
  authedRedirectTo?: To
  notAuthedRedirectTo?: To
  children: React.ReactNode
}

export const EnsureTabMemberAuth: React.FC<EnsureTabMemberAuthProps> = ({
  authedRedirectTo,
  notAuthedRedirectTo,
  children,
}) => {
  const [preventAuthRedirect] = useQueryParam(
    'preventAuthRedirect',
    BooleanParam,
  )
  const [authToken] = useAuthToken()

  const isTabMemberAuthed =
    authToken &&
    isAuthTokenValid(authToken) &&
    authToken.tokenType === 'TabMember'

  if (isTabMemberAuthed && authedRedirectTo && !preventAuthRedirect) {
    return <Navigate to={authedRedirectTo} />
  }
  if (!isTabMemberAuthed && notAuthedRedirectTo && !preventAuthRedirect) {
    return <Navigate to={notAuthedRedirectTo} />
  }

  return <>{children}</>
}
