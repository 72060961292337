import {makeAsComponent} from '@cheddarup/react-util'
import {cva} from 'class-variance-authority'

import {VariantsProps, cn} from '../utils'

export type TextVariant = 'default' | 'subtle' | 'danger'

export const text = cva('font-body font-normal', {
  variants: {
    variant: {
      default: 'text-inherit',
      subtle: 'text-gray400',
      danger: 'text-orange-500',
    },
  },
})

export interface TextProps extends VariantsProps<typeof text> {}

export const Text = makeAsComponent<'span', TextProps>(
  ({as: Comp = 'span', className, variant = 'default', ...restProps}) => {
    // GlamourAbsolute does not look good with subpixel antialiase
    const shouldAntialias = className?.includes('font-accentAlt')

    return (
      <Comp
        className={cn(
          `Text Text--${variant}`,
          text({variant}),
          shouldAntialias && 'antialiased',
          className,
        )}
        {...restProps}
      />
    )
  },
)
