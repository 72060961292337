import {z} from '@cheddarup/util'
import {
  leaderboardParticipantItemSchema,
  leaderboardTeamItemSchema,
  participantOptionsSchema,
  participantSuggestionSchema,
  publicParticipantSchema,
  s3ImageMetadataSchema,
  s3ImageSchema,
  supporterFeedSchema,
} from '../schemas'
import {makeEndpoint} from '../utils'

export default {
  list: makeEndpoint({
    path: 'participants',
    queryParamsSchema: z.object({
      tab_slug: z.string(),
    }),
    responseSchema: publicParticipantSchema.array(),
  }),
  detail: makeEndpoint({
    path: 'participants/:participantId',
    queryParamsSchema: z.object({
      tab_slug: z.string(),
    }),
    responseSchema: publicParticipantSchema,
  }),
  create: makeEndpoint({
    path: 'participants',
    method: 'POST',
    bodySchema: z.object({
      tab_slug: z.string(),
      captchaToken: z.string().optional(),
      participant: z.object({
        email: z.string(),
        first_name: z.string(),
        last_name: z.string(),
        team_id: z.number().optional(), // TODO: not sure if `team_id` works yet
      }),
    }),
    responseSchema: publicParticipantSchema,
  }),
  update: makeEndpoint({
    path: 'participants/:participantId',
    method: 'PATCH',
    bodySchema: z.object({
      tab_slug: z.string(),
      participant: z
        .object({
          first_name: z.string(),
          last_name: z.string(),
          team_id: z.number(),
          profile_picture_id: z.number().nullable(),
          personal_video_id: z.number().nullable(),
          options: participantOptionsSchema.partial(),
        })
        .partial(),
    }),
    responseSchema: publicParticipantSchema,
  }),
  suggestions: makeEndpoint({
    path: 'participants/suggestions',
    queryParamsSchema: z
      .object({
        tab_slug: z.string().or(z.number()),
        keyword: z.string(),
      })
      .optional(),
    responseSchema: participantSuggestionSchema.array(),
  }),
  requestLoginLink: makeEndpoint({
    path: 'participants/request_login',
    method: 'POST',
    bodySchema: z.object({
      tab_slug: z.string(),
      email: z.string(),
    }),
    responseSchema: z.object({message: z.string()}),
  }),
  createProfilePicture: makeEndpoint({
    path: 'participants/:participantId/create_profile_picture',
    method: 'POST',
    bodySchema: z.object({
      tab_slug: z.string(),
      objectName: z.string(),
      upload_path: z.string(),
      metadata: s3ImageMetadataSchema,
    }),
    responseSchema: s3ImageSchema,
  }),
  updateProfilePicture: makeEndpoint({
    path: 'participants/:participantId/update_profile_picture/:imageId',
    method: 'PATCH',
    bodySchema: z.object({
      tab_slug: z.string(),
      metadata: s3ImageMetadataSchema.partial(),
    }),
    responseSchema: s3ImageSchema,
  }),
  deleteProfilePicture: makeEndpoint({
    path: 'participants/:participantId/delete_profile_picture/:imageId',
    method: 'DELETE',
    bodySchema: z.object({
      tab_slug: z.string(),
    }),
    responseSchema: z.any(),
  }),
  createPersonalVideo: makeEndpoint({
    path: 'participants/:participantId/create_personal_video',
    method: 'POST',
    bodySchema: z.object({
      tab_slug: z.string(),
      objectName: z.string(),
      upload_path: z.string(),
      metadata: s3ImageMetadataSchema,
    }),
    responseSchema: s3ImageSchema,
  }),
  updatePersonalVideo: makeEndpoint({
    path: 'participants/:participantId/update_personal_video/:videoId',
    method: 'PATCH',
    bodySchema: z.object({
      tab_slug: z.string(),
      metadata: s3ImageMetadataSchema.partial(),
    }),
    responseSchema: s3ImageSchema,
  }),
  deletePersonalVideo: makeEndpoint({
    path: 'participants/:participantId/delete_personal_video/:videoId',
    method: 'DELETE',
    bodySchema: z.object({
      tab_slug: z.string(),
    }),
    responseSchema: z.any(),
  }),
  // TODO: `supporterFeed` and `leaderboard` endpoints belong to public-tabs
  supporterFeed: makeEndpoint({
    path: 'collections/:fundraiserId/supporter_feed',
    responseSchema: supporterFeedSchema.array(),
  }),
  leaderboard: makeEndpoint({
    path: 'collections/:fundraiserId/leaderboard',
    responseSchema: z.union([
      leaderboardParticipantItemSchema.array(),
      leaderboardTeamItemSchema.array(),
    ]),
  }),
}
